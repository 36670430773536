<template>
  <q-layout view="hHh lpR fFf">
    <q-page-container>
      <router-view />
    </q-page-container>
    <q-footer bordered class="bg-primary text-white text-center">
        <div class="q-ma-sm">2022 - ALA-15V <a href="www.ala15v.com" target="_blank" class="text-secondary">www.ala15v.com</a> (Build {{ version }})</div>
    </q-footer>
  </q-layout>
</template>

<script>
export default {
  setup() {
    
  },
  data() {
    return {
      version: import.meta.env.VITE_BUILD_VERSION || 'local'
    }
  }
}
</script>