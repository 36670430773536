<template>
  <q-page padding>
  <div class="div-container bg-yellow-2 row justify-evenly q-ma-xs q-pa-md full-height rounded-borders">
    <div class="col-12 full-width q-pb-md text-orange-10 text-h4 text-bold">
      <q-icon size="lg" name="fa-solid fa-jet-fighter" />
      Estadísticas Globales por Armamento
      <q-separator size="0"></q-separator>
      <span class="text-subtitle2">Armamento empleado por el piloto y su módulo de vuelo.</span>
      <q-separator class="q-mt-md"></q-separator>
    </div>
    <q-table
      :rows="rows"
      :columns="columns"
      row-key="id"
      :loading="loading"
      :visible-columns="visibleColumns"
      no-data-label="No hay datos."
      no-results-label="Sin resultados."
      loading-label="Cargando información..."
      :rows-per-page-options="[10, 25,50,100]"
      :filter="filter"
      binary-state-sort
      class="col-12"
      bordered
      table-header-class="bg-primary text-white"
    >
      <template v-slot:top-left>
        <q-input debounce="300" v-model="filter" placeholder="Buscar">
          <template v-slot:append>
            <q-icon name="search" />
          </template>
        </q-input>
      </template>
    </q-table>
  </div>  
  </q-page>
</template>

<script>
import { module_kills } from "../models"

export default {
  components: {
  },
  data() {
    return {
      error: null,
      filter: '',
      loading: true,
      visibleColumns: [
        'name',
        'module_name',
        'weapon',
        'kills',
        'hits',
        'shots',
        'category_aaa'
      ],
      columns: [
        {
          name: 'id',
          field: 'id',
          required: false,
        },
        {
          name: 'name',
          field: 'name',
          label: 'Piloto',
          sortable: true,
          classes: 'text-primary',
          align: 'right',
          headerStyle: 'width: 250px',
        },
        {
          name: 'module_name',
          field: 'module_name',
          label: 'Módulo',
          sortable: true,
          classes: 'text-primary',
          align: 'left',
        },
        {
          name: 'weapon',
          field: 'weapon',
          label: 'Arma',
          sortable: true,
          classes: 'text-primary',
          align: 'center',
        },
        {
          name: 'kills',
          field: 'kills',
          label: 'Muertes',
          sortable: true,
          classes: 'text-primary',
          align: 'center',
        },
        {
          name: 'shots',
          field: 'shots',
          label: 'Lanzamientos',
          sortable: true,
          classes: 'text-primary',
          align: 'center',
        },
        {
          name: 'hits',
          field: 'hits',
          label: '%Efectividad',
          sortable: true,
          format: (value, row) => {
            return ((parseInt(row['kills']) / parseInt(row['shots']))*100).toFixed(2) + '%'
          },
          classes: 'text-primary',
          align: 'center',
        },
      ],
      rows: [],
    }
  },
  mounted() {
    const requests = [
      this.getAll(),
    ]

    Promise.all(requests)
      .catch(error => {
        this.error = error
        console.log(error)
      })
      .finally(() => {
        this.loading = false
      })
  },
  methods: {
    async getAll() {
      const response = await module_kills.getAll(this, this.filter, 999999, 1)
      if (response.values) {
        this.rows = response.values
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.div-container {
  border: 2px solid $yellow-9;
}

.my-table {
  border: 2px solid $yellow-9;
}

.my-header {
  background-color: $orange-8;
}

</style>