import { flattenGraphQL } from "../utils"
import { orderBy } from "lodash"

class ModulesKillsModel {
    constructor() {
        this.base_url = import.meta.env.VITE_DIRECTUS_URL
    }
    queries() {
        return {
            getWeaponKillsByUCID: `query getWeaponsByUCIUD($ucid: String){
                slmod_modules_kills_aggregated(groupBy: ["weapon"],filter: {slmod_modules: {slmod_main: {ucid: {_eq: $ucid}}}}) {
                    group
                    sum {
                        kills
                    }
                }
            }`,
            getAll: `query slmodModules($page: Int!, $limit: Int!, $search: String!){
                slmod_modules_kills(
                    limit: $limit,
                    page: $page,
                    search: $search,
                    sort: ["-kills"],
                    filter: {
                        slmod_modules: {
                            slmod_main: {
                                name: { _starts_with: "=ALA15V="}
                            }
                        }
                    }
                ) {
                    id
                    slmod_modules {
                        slmod_main {
                            name
                        }
                        module_name
                    }
                    kills
                    weapon
                    hits
                    shots
                    category_aaa
                    category_sam
                    category_unarmored
                    category_apcs
                    category_static
                    category_ifvs
                    category_forts
                    category_bombers
                    category_fighters
                    category_attack
                    category_support
                    category_infantry
                    category_arty
                    category_warships
                    category_tanks
                }
            }`,         
        }
    }
    async getWeaponKillsByUCID(app, ucid) {
        try {
            const response = await app.$axios.post('/graphql', {
                query: this.queries().getWeaponKillsByUCID,
                variables: {
                    ucid: ucid
                }
            })
            const payload = response.data
            let result = flattenGraphQL(payload)       
            result.values = orderBy(result.values, ['kills'], ['desc'])

            return result

        } catch(error) {
            console.log('ERROR on getWeaponKillsByUCID')
            console.error(error)
        }
    }
    async getAll(app, search='', limit=100, page=1) {
        try {
            const response = await app.$axios.post('/graphql', {
                query: this.queries().getAll,
                variables: {
                    limit: limit,
                    page: page,
                    search: search
                }
            })
            const payload = response.data
            let result = flattenGraphQL(payload)       

            return result

        } catch(error) {
            console.log('ERROR on getAll')
            console.error(error)
        }        
    }

}

export default new ModulesKillsModel()