<template>
  <q-page padding>
    <div class="row div-container bg-yellow-2 full-width row text-h4 q-ma-xs q-pa-md rounded-borders">
      <div class="col-12 full-width q-pb-md text-orange-10">
        <q-icon size="lg" name="fa-solid fa-id-card" />
        Estadísticas Globales
        <q-separator class="q-ma-none" size="0"></q-separator>
        <span class="text-caption q-ma-none">Click en la imagen para acceder a ellas</span>
        <q-separator size="0"></q-separator>
      </div>
      <div class="col-12 row q-pa-none q-ma-none justify-center">
            <q-card class="col-md-3 col-sm-12 my-card cursor-pointer q-hoverable" v-ripple @click="pushTo('landing')">
              <q-img :src="imagePlaceHolder" height="250px" />
              <q-card-section>
                <div class="text-h6">Por Módulo</div>
                <q-separator size="2px"></q-separator>
                <div class="text-subtitle2 q-mt-md">Los datos están relacionados con el piloto y su módulo de vuelo.</div>
              </q-card-section>
            </q-card>
            <q-card class="col-md-3 col-sm-12 my-card cursor-pointer q-hoverable" v-ripple @click="pushTo('landing')">
              <q-img :src="imagePlaceHolder" height="250px" />
              <q-card-section>
                <div class="text-h6">Por Armamento</div>
                <q-separator size="2px"></q-separator>
                <div class="text-subtitle2 q-mt-md">Armamento empleado por el piloto y su módulo de vuelo.</div>
              </q-card-section>
            </q-card>
            <q-card class="col-md-3 col-sm-12 my-card cursor-pointer q-hoverable" v-ripple @click="pushTo('landing')">
              <q-img :src="imagePlaceHolder" height="250px" />
              <q-card-section>
                <div class="text-h6">Por unidades destruidas</div>
                <q-separator size="2px"></q-separator>
                <div class="text-subtitle2 q-mt-md">Tipo de unidades destruidas con el arma y el módulo de vuelo.</div>
              </q-card-section>
            </q-card>
            <q-card class="col-md-3 col-sm-12 my-card cursor-pointer q-hoverable" v-ripple @click="pushTo('landing')">
              <q-img :src="imagePlaceHolder" height="250px" />
              <q-card-section>
                <div class="text-h6">Unidades enemigas</div>
                <q-separator size="2px"></q-separator>
                <div class="text-subtitle2 q-mt-md">Unidades enemigas que más destrucciones generan con su armamento.</div>
              </q-card-section>
            </q-card>
      
      </div>
    </div>
  </q-page>
</template>

<script>
import imagePlaceHolder from '@/assets/placeholder_image.png'

export default {
  data() {
    return {
      imagePlaceHolder: imagePlaceHolder
    }
  },
  methods: {
    pushTo(where) {
      this.$router.push({name: where})
    }
  }
}
</script>

<style scoped lang="scss">
.my-card {
  border: 2px solid $yellow-9;
}
.div-container {
  border: 2px solid $yellow-9;
}
</style>