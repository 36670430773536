<script setup>
import { onMounted } from "@vue/runtime-core"

const redirect_to = import.meta.env.VITE_DIRECTUS_URL + "auth/login/discord?redirect=" + import.meta.env.VITE_REDIRECT_TO

</script>

<template>
  <q-page padding class="bg-primary">
    <div class="row item-start">">
      <div class="absolute-center">
        <p class="text-h5 text-center text-grey-5 text-weight-bold mobile-only">HQ ALA-15V</p>
        <p class="text-h2 text-center text-grey-5 text-weight-bold desktop-only">HQ ALA-15V</p>
        <p class="text-subtitle text-center text-grey-5">Cuartel General del ALA-15V Virtual</p>
        <q-btn class="full-width q-pa-xs" :href="redirect_to"><q-img src="@/assets/logo_ala15.png" /></q-btn>
        <div class="q-pa-lg"><q-btn class="full-width" color="secondary" text-color="primary" align="center" rounded icon="lock" size="50px" :href="redirect_to" /></div>
      </div>
    </div>
  </q-page>
</template>
