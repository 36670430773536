import { flattenGraphQL } from "../utils"

class SlmodModulesKilllistModel {
    constructor() {
        this.base_url = import.meta.env.VITE_DIRECTUS_URL
    }
    queries() {
        return {
            getAll: `query slmodModules($page: Int!, $limit: Int!, $search: String!){
                slmod_modules_killlist(
                    limit: $limit,
                    page: $page,
                    search: $search,
                    sort: ["-kills"],
                    filter: {
                        slmod_modules_kills: {
                            slmod_modules: {
                                slmod_main: {
                                    name: { _starts_with: "=ALA15V="}
                                }
                            }
                        }
                    }
                ) {
                    id
                    slmod_modules_kills {
                        slmod_modules {
                            slmod_main {
                                name
                            }
                            module_name
                        }
                        weapon
                    }
                    kills
                    unit_name
                }
            }`,           
        }
    }
    async getAll(app, search='', limit=100, page=1) {
        try {
            const response = await app.$axios.post('/graphql', {
                query: this.queries().getAll,
                variables: {
                    limit: limit,
                    page: page,
                    search: search
                }
            })
            const payload = response.data
            let result = flattenGraphQL(payload)       

            return result

        } catch(error) {
            console.log('ERROR on getAll')
            console.error(error)
        }
    }

}

export default new SlmodModulesKilllistModel()