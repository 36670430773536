class PolarChart {
    constructor(title) {
        this.title = title || ''
    }
    chartData() {
        return {
            type: 'polarArea',
            data: {
                labels: [],
                datasets: [{
                    data: [],
                    backgroundColor: ['#0D0221', '#0F084B', '#26408B', '#A6CFD5', '#C2E7D9'],
                    borderWidth: 1,
                }]
            },
            options: {
                responsive: true,
                plugins: {
                    title: {
                        display: true,
                        text: this.title
                    },
                    legend: {
                        display: true,
                        position: 'bottom'
                    }
                },
                scales: {
                    y: {
                      beginAtZero: true
                    }
                }
            }
        }
    }
}

export default PolarChart