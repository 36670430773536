<template>
  <q-page padding>
    <Loading :loading="loading" />
    <template v-if="!loading">
      <div v-if="error" class="">
        <q-banner rounded class="bg-red text-white">
          <template v-slot:avatar>
            <q-icon size="xl" color="red-1" name="fa-solid fa-circle-xmark" />
          </template>
          <span class="text-subtitle1">Ha ocurrido un error</span>
          <q-separator></q-separator>
          <span class="text-subtitle1">{{ error }}</span>
          <template v-slot:action>
            <q-btn flat color="white" label="Volver a entrar" :to="{name: 'login'}" />
          </template>
        </q-banner>
      </div>      
      <div class="div-container bg-yellow-2 row justify-evenly q-ma-xs q-pa-md full-height rounded-borders">
        <div class="col-12 full-width q-pb-md text-orange-10 text-h4 text-bold">
          <q-icon size="lg" name="fa-solid fa-satellite-dish" />
          Estadísticas Top 5
          <q-separator size="0"></q-separator>
          <span class="text-subtitle2">Selección de estadísticas generales y por eventos</span>
          <q-separator class="q-mt-md"></q-separator>
        </div>
        <div class="row col-12 q-gutter-md">
          <q-card class="col-sm-12 col-md">
            <q-img src="@/assets/stats_graph.jpg" height="150px" position="25% 0">
              <div class="absolute-top text-h6 text-center text-bold">
                Generales
              </div>
            </q-img>
            <q-card-section class="row justify-center">
              <div class="col-sm-12 col-md">
                <canvas id="top5Modules"></canvas>
              </div>
              <q-separator size="2px" vertical></q-separator>
              <q-separator class="col-12 mobile-only" size="2px"></q-separator>
              <div class="col-sm-12 col-md">
                <canvas id="top5Weapons"></canvas>
              </div>
              <q-separator class="col-12" size="2px"></q-separator>
              <div class="col-sm-12 col-md-8">
                <canvas id="top5Killers"></canvas>
              </div>
            </q-card-section>
          </q-card>
          <q-card class="col-sm-12 col-md">
            <q-img src="@/assets/eventos.jpg" height="150px" position="25% 0">
              <div class="absolute-top text-h6 text-center text-bold">
                Eventos
              </div>
            </q-img>
            <q-card-section class="row justify-center">
              <div class="col-sm-12 col-md">
                <canvas id="top5ModulesEvent"></canvas>
              </div>
              <q-separator size="2px" vertical></q-separator>
              <q-separator class="col-12 mobile-only" size="2px"></q-separator>
              <div class="col-sm-12 col-md">
                <canvas id="top5WeaponsEvent"></canvas>
              </div>
              <q-separator class="col-12" size="2px"></q-separator>
              <div class="col-sm-12 col-md-8">
                <canvas id="top5KillersEvent"></canvas>
              </div>            
            </q-card-section>
          </q-card>
        </div>
      </div>
    </template>
  </q-page>
</template>

<script>
import  Loading from '../components/Loading.vue'
import { slmod_composed } from '../models'
import Chart from '../services/chart-load'
import { PieChart, DoughnutChart, PolarChart } from '../charts'

export default {
  components: {
    Loading,
  },
  data() {
    return {
      error: null,
      loading: true,
      top5ModulesChartData: new PieChart('Top 5 Módulos Utilizados por horas de vuelo').chartData(),
      top5WeaponsChartData: new PolarChart('Top 5 Armas Aliadas por Kills').chartData(),
      top5KillersChartData: new DoughnutChart('Top 5 Armas Enemigas que más matan').chartData(),
      top5ModulesEventChartData: new PieChart('Top 5 Módulos Utilizados por horas de vuelo').chartData(),
      top5WeaponsEventChartData: new PolarChart('Top 5 Armas Aliadas por Kills').chartData(),
      top5KillersEventChartData: new DoughnutChart('Top 5 Armas Enemigas que más matan').chartData(),
    }
  },
  mounted() {
    // this.top5ModulesChartData = new PieChart()
    const requests = [
        this.getTopModules(),
        this.getTopWeapons(),
        this.getTopKillers(),
        this.getTopModulesEvent(),
        this.getTopWeaponsEvent(),
        this.getTopKillersEvent(),
      ]

    Promise.all(requests)
      .then(() => {
        this.loading = false
        // wait dom loaded after loading=false
        this.$nextTick(() => {
          let top5ModulesChart = new Chart(document.getElementById('top5Modules'), this.top5ModulesChartData)
          top5ModulesChart.update()

          let top5WeaponsChart = new Chart(document.getElementById('top5Weapons'), this.top5WeaponsChartData)
          top5WeaponsChart.update()

          let top5KillersChart = new Chart(document.getElementById('top5Killers'), this.top5KillersChartData)
          top5KillersChart.update()

          let top5ModulesEventChart = new Chart(document.getElementById('top5ModulesEvent'), this.top5ModulesEventChartData)
          top5ModulesEventChart.update()

          let top5WeaponsEventChart = new Chart(document.getElementById('top5WeaponsEvent'), this.top5WeaponsEventChartData)
          top5WeaponsEventChart.update()

          let top5KillersEventChart = new Chart(document.getElementById('top5KillersEvent'), this.top5KillersEventChartData)
          top5KillersEventChart.update()

        })
      })
      .catch(error => {
        this.error = error
        this.loading = false
      })
  },
  methods: {
    async getTopModules() {
      const response = await slmod_composed.getTopModules(this)
      if (response) {
        response.forEach(element => {
          this.top5ModulesChartData.data.labels.push(element.module_name)
          this.top5ModulesChartData.data.datasets[0].data.push((element.time_inair / 3600).toFixed(2))
        });
      }
    },
    async getTopWeapons() {
      const response = await slmod_composed.getTopWeapons(this)
      if (response) {
        response.forEach(element => {
          this.top5WeaponsChartData.data.labels.push(element.weapon)
          this.top5WeaponsChartData.data.datasets[0].data.push(element.kills)
        })
      }
    },
    async getTopKillers() {
      const response = await slmod_composed.getTopKillers(this)
      if (response) {
        response.forEach(element => {
          this.top5KillersChartData.data.labels.push(`${element.unit_name} (${element.weapon})`)
          this.top5KillersChartData.data.datasets[0].data.push(element.hits)
        })
      }
    },
    async getTopModulesEvent() {
      const response = await slmod_composed.getTopModulesEvent(this)
      if (response) {
        response.forEach(element => {
          this.top5ModulesEventChartData.data.labels.push(element.module_name)
          this.top5ModulesEventChartData.data.datasets[0].data.push((element.time_inair / 3600).toFixed(2))
        });
      }
    },
    async getTopWeaponsEvent() {
      const response = await slmod_composed.getTopWeaponsEvent(this)
      if (response) {
        response.forEach(element => {
          this.top5WeaponsEventChartData.data.labels.push(element.weapon)
          this.top5WeaponsEventChartData.data.datasets[0].data.push(element.kills)
        })
      }
    },
    async getTopKillersEvent() {
      const response = await slmod_composed.getTopKillersEvent(this)
      if (response) {
        response.forEach(element => {
          this.top5KillersEventChartData.data.labels.push(`${element.unit_name} (${element.weapon})`)
          this.top5KillersEventChartData.data.datasets[0].data.push(element.hits)
        })
      }
    }    
  }
}
</script>

<style scoped lang="scss">
.my-card {
  border: 2px solid $yellow-9;
  width: 300px;
}
.div-container {
  border: 2px solid $yellow-9;
}
.trend {
  height: 150px;
}

.section-middle {
  height: 120px;
}
.section-top {
  height: 120px;
}
.section-bottom {
  height: 300px;
}
</style>