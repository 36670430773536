import _ from 'lodash'
import { orderBy } from 'lodash';
import { flattenGraphQL } from '../utils'

const queries = {
    getAllModulesWithUsername: `query getUsersModules{
        slmod_modules {
            id
            module_name
            time_inair
            slmod_main {
                name
            }
        }
    }`,
    modulesStatsByID: `query getModules($id: Float){
        slmod_modules(filter: {slmod_main: {id: {_eq: $id}}},  sort: ["-time_inair"]) {
            module_name
            takeoffs
            landings
            time_inair
            losses_death
            losses_crash
            losses_eject
        }
    }`,
    getModulesTimeAirByUCID: `
        query getModules($ucid: String){
            slmod_modules_aggregated(groupBy: ["module_name"], filter: {slmod_main: {ucid: {_eq: $ucid}}}) {
                group
                sum {
                    time_inair
                }
            }
        }
    `
}

const getAllModulesWithUsername = async function (http) {
    const response = await http.post('/graphql', {
        query: queries.getAllModulesWithUsername,
    })
    return response.data.data
}

const modulesStatsByID = async function (http, id) {
    const response = await http.post('/graphql', {
        query: queries.modulesStatsByID,
        variables: {
            id: id
        }
    })
    return response.data.data.slmod_modules
}

const getAllModulesWithUsernameGroupByUsername = async function (http) {
    const response = await http.post('/graphql', {
        query: queries.getAllModulesWithUsername,
    })
    const collection = response.data.data
    return _(collection.slmod_modules)
    .groupBy(x => x.slmod_main.name)
    .map((value, key) => ({username:key, module_info: value}))
    .value()
}

const getAllModulesWithUsernameGroupByUsernameRowsColumns = async function (http) {
    const response = await http.post('/graphql', {
        query: queries.getAllModulesWithUsername,
    })
    const collection = response.data.data
    const columns = null
    const rows = null

    const collection_ordered = _(collection.slmod_modules)
    .groupBy(x => x.slmod_main.name)
    .map((value, key) => ({Piloto:key, module_info: 'value'}))
    .value()

    return collection_ordered
}


const getModulesTimeAirByUCID = async function (app, ucid) {
    try {
        const response = await app.$axios.post('/graphql', {
            query: queries.getModulesTimeAirByUCID,
            variables: {
                ucid: ucid
            }
        })
        const payload = response.data
        let result = flattenGraphQL(payload)
        result.values = orderBy(result.values, ['time_inair'], ['desc'])

        return result

    } catch(error) {
        // TODO: error control
        console.log('ERROR on getModulesTimeAirByUCID')
        console.log(error)
    }    
}

export {
    getAllModulesWithUsername,
    getAllModulesWithUsernameGroupByUsername,
    getAllModulesWithUsernameGroupByUsernameRowsColumns,
    modulesStatsByID,
    getModulesTimeAirByUCID
}