class DoughnutChart {
    constructor(title) {
        this.title = title || ''
    }
    chartData() {
        return {
            type: 'doughnut',
            data: {
                labels: [],
                datasets: [{
                    data: [],
                    backgroundColor: ['#1C3144', '#D00000', '#FFBA08', '#A2AEBB', '#3F88C5'],
                }]
            },
            options: {
                responsive: true,
                plugins: {
                    title: {
                        display: true,
                        text: this.title
                    },
                    legend: {
                        display: true,
                        position: 'bottom'
                    }
                }
            }
        }
    }
}

export default DoughnutChart