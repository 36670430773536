const queries = {
    getDirectusUser: `query {
        users_me {
            id
            email
            dcs_ucid
            pilot_name
            role {
                id
            }
            avatar {
                id
            }
        }
    }`
}

const getDirectusUser = async function(app) {
    try {
        const response = await app.$axios.post('/graphql/system', {
            query: queries.getDirectusUser,
        })
        const payload = response.data.data.users_me
        return {
            id: payload.id,
            email: payload.email,
            dcs_ucid: payload.dcs_ucid,
            pilot_name: payload.pilot_name,
            avatar: payload.avatar && payload.avatar.id || null,
            role: payload.role.id
        }
    } catch(error) {
        // TODO: error control
        console.log('ERROR on getDirectusUser')
        console.log(error)
    }
}

export {
    getDirectusUser
}
