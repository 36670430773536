<template>
    <div class="row div-container bg-yellow-2 full-width text-h4 q-ma-xs q-pa-md rounded-borders">
        <div v-if="isNullPilotName" class="col-12 text-orange-10">
            <q-banner class="bg-yellow-2">
            <template v-slot:avatar>
                <q-icon name="fa-solid fa-circle-question" color="orange-10" />
            </template>
            Click en un piloto de la tabla
            </q-banner>
        </div>
        <div class="row col-12 text-orange-10" v-else>
            <div class="col-12 q-mt-xs row text-subtitle1 text-weight-bold">
                <BoxData1
                    icon="fa-solid fa-user"
                    label="Piloto"
                    :info="pilotName"
                    info_label=""
                />
            </div>
            <div class="q-mt-xs row col-12">
                <BoxData1
                    icon="fa-solid fa-jet-fighter"
                    label="Horas de vuelo totales"
                    :info="(time_inair / 3600).toFixed(2)"
                    info_label="horas"
                />
                <BoxData1
                    icon="fa-solid fa-satellite-dish"
                    label="Horas de vuelo en operaciones"
                    :info="(events_time_inair / 3600).toFixed(2)"
                    info_label="horas"
                />
                <BoxData1
                    icon="fa-solid fa-satellite-dish"
                    label="Asistencia a eventos"
                    :info="events_count"
                    info_label="asistencias"
                />
            </div>
            <div class="q-mt-xs row col-12">
                <div class="q-ma-xs  col-md col-sm-12 col-xs-12">
                    <q-list bordered class="rounded-borders text-subtitle1 bg-white full-height">
                    <q-item-label header class="text-h5">Últimos 5 eventos</q-item-label>
                    <template v-if="events.length > 0" v-for="(event, index) in events.slice(0,5)" :key="index">
                        <q-item clickable v-ripple :to="{ name: 'operations', params: {ucid: event.slmod_main.ucid, name: pilotName} }">
                            <q-item-section avatar>
                            <q-avatar square size="xl">
                                <img v-if="event.events.event_map == 'sy'" src="https://www.digitalcombatsimulator.com/upload/iblock/7e3/DCS_Syria_700x1000_v1.jpg" />
                                <img v-else-if="event.events.event_map == 'pg'" src="https://www.digitalcombatsimulator.com/upload/iblock/922/DCS_Persian-Gulf_700x1000_v1b.jpg" />
                                <img v-else-if="event.events.event_map == 'ca'" src="https://www.digitalcombatsimulator.com/upload/iblock/792/DCS-World-2-5_banner-2018.jpg" />
                                <img v-else-if="event.events.event_map == 'mi'" src="https://www.digitalcombatsimulator.com/upload/iblock/792/DCS-World-2-5_banner-2018.jpg" />
                                <img v-else src="@/assets/placeholder_image.png" />
                            </q-avatar>
                            </q-item-section>

                            <q-item-section>
                            <q-item-label lines="1">{{ event.events.name }}</q-item-label>
                            <q-item-label caption lines="2">
                                <span class="text-weight-bold">{{ event.module_name }} ({{ (event.time_inair / 3600).toFixed(2) }} horas)</span>
                                // Estado: {{ isSurvival(event) ? 'R.T.B.' : 'K.I.A.' }}
                                <q-separator size="0"></q-separator>
                                <span class="text-weight-bold">Editor: {{ !event.events.event_editor ? 'sin definir' : event.events.event_editor.pilot_name }}</span>
                            </q-item-label>
                            </q-item-section>

                            <q-item-section side top>
                            {{ formatDate(event.events.event_date, 'dd-MM-Y') }}
                            </q-item-section>
                        </q-item>
                        <q-separator inset="item" />
                    </template>
                        <q-item v-else>
                            <q-item-section>
                            <q-item-label lines="1">Sin eventos</q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                </div>
                <div class="q-ma-xs col-md col-sm-12 col-xs-12">
                    <q-list bordered class="rounded-borders text-subtitle1 bg-white full-height">
                    <q-item-label header class="text-h5">Top 5 Módulos</q-item-label>
                    <template v-for="(module, index) in modules.slice(0,5)" :key="index">
                        <q-item>
                            <q-item-section avatar>
                            <q-avatar square size="xl">
                                <img src="@/assets/placeholder_image.png" />
                            </q-avatar>
                            </q-item-section>

                            <q-item-section>
                            <q-item-label lines="1">{{ module.module_name }}</q-item-label>
                            <q-item-label caption lines="2">
                                <span class="text-weight-bold">{{ module.module_name }} ({{ (module.time_inair / 3600).toFixed(2) }} horas)</span>
                                <q-separator size="0"></q-separator>
                                <!-- <span class="text-weight-bold">Editor: {{ !event.events.event_editor ? 'sin definir' : event.events.event_editor.pilot_name }}</span> -->
                            </q-item-label>
                            </q-item-section>

                            <q-item-section side top>
                            {{ (module.time_inair / 3600).toFixed(2) }} horas
                            </q-item-section>
                        </q-item>
                        <q-separator inset="item" />
                    </template>
                    </q-list>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BoxData1 from './BoxData1.vue'
import { parseISO, format } from "date-fns"

export default {
    components: {
        BoxData1
    },
    props: ['pilotName', 'pilotInfo', 'filter'],
    data() {
        return {
            isNullPilotName: true,
            modules: [],
            events: [],
            time_inair: 0,
            events_time_inair: 0,
            events_count: 0,
        }
    },
    watch: {
        pilotName() {
            if (this.pilotName) this.isNullPilotName = false
        },
        pilotInfo() {
            this.parsePilotInfo()
        },
    },
    methods: {
        parsePilotInfo() {
            this.modules = this.pilotInfo.modules
            this.events = this.pilotInfo.events
            this.events_count = this.pilotInfo.events.length
            this.events_time_inair = this.pilotInfo.events_time_inair
            this.time_inair = this.pilotInfo.time_inair
        },
        isSurvival(event) {
            if (event.losses_death == 0 &&
                event.losses_crash == 0 &&
                event.losses_eject == 0 &&
                event.losses_error == 0
            ) return true
            return false
        },
        formatDate(dateTarget, formatRequired) {
            return format(parseISO(dateTarget), formatRequired)
        }
    }
}
</script>