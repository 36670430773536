import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'
import { userStore } from '../store'
import HomeView from '../views/HomeView.vue'
import LoginView from '../views/LoginView.vue'
import OperationsView from '../views/OperationsView.vue'
import GeneralStatsView from '../views/GeneralStatsView.vue'
import SandboxView from '../views/SandboxView.vue'
import AuthView from '../views/AuthView.vue'
import FullScreen from '../layouts/FullScreen.vue'
import Internal from '../layouts/Internal.vue'
import RegisterProcess from '../views/RegisterProcess.vue'
import StatsModules from '../views/StatsModules.vue'
import StatsWeapons from '../views/StatsWeapons.vue'
import StatsEnemyKills from '../views/StatsEnemyKills.vue'
import StatsEnemyDeath from '../views/StatsEnemyDeath.vue'
import StatsSelectGrid from '../views/StatsSelectGrid.vue'
import PilotsView from '../views/PilotsView.vue'
import TopStats from '../views/TopStats.vue'

const router = createRouter({
  history: createWebHashHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      component: FullScreen,
      children: [
        {path: '', component: AuthView, name: 'auth'},
        {path: 'login', component: LoginView, name: 'login'}
      ],
      meta: { requiresAuth: false }
    },
    {
      path: '/internal',
      redirect: {name: 'landing'},
      component: Internal,
      children: [
        {path: 'landing', component: HomeView, name: 'landing', meta: {title: 'HQ ALA-15V - Runway 15'}},
        {path: 'operations/:ucid/:name', component: OperationsView, name: 'operations', meta: {title: 'HQ ALA-15V - Operaciones'}},
        {path: 'stats', component: GeneralStatsView, name: 'stats', meta: {title: 'HQ ALA-15V - Estadísticas'}},
        {path: 'statsSelect', component: StatsSelectGrid, name: 'statsSelect', meta: {title: 'HQ ALA-15V - Estadísticas Globales'}},
        {path: 'statsModules', component: StatsModules, name: 'statsModules', meta: {title: 'HQ ALA-15V - Estadísticas Globales por Módulo'}},
        {path: 'statsWeapons', component: StatsWeapons, name: 'statsWeapons', meta: {title: 'HQ ALA-15V - Estadísticas Globales por Armamento'}},
        {path: 'statsKills', component: StatsEnemyKills, name: 'statsKills', meta: {title: 'HQ ALA-15V - Estadísticas Globales por Unidades Destruidas'}},
        {path: 'statsDeath', component: StatsEnemyDeath, name: 'statsDeath', meta: {title: 'HQ ALA-15V - Estadísticas Globales por Muerte'}},
        {path: 'topStats', component: TopStats, name: 'topStats', meta: {title: 'HQ ALA-15V - Estadísticas Top'}},
        {path: 'register', component: RegisterProcess, name: 'register', meta: {title: 'HQ ALA-15V - Proceso de Registro'}},
        {path: 'pilotsMain', component: PilotsView, name: 'pilotsMain', meta: {title: 'HQ ALA-15V - Pilotos'}},
        {path: 'sandbox', component: SandboxView, name: 'sandbox', meta: {title: 'HQ ALA-15V - sandbox'}}
      ],
      meta: { requiresAuth: true, title: 'Internal View' }
    },
  ]
})

router.beforeEach((to, from, next) => {
  const store = userStore()
  const authenticatedUser = store.isAuthenticated
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)

  // Change title page
  document.title = to.meta.title || 'HQ ALA-15V'
  if(requiresAuth && !authenticatedUser) {
    next('/login');
  } 
  else next()
})


export default router
