<template>
    <div class="absolute-center full-width text-center" v-if="loading">
        <span class="text-orange-8 text-h6 text-weight-bolder">Calentando motores ...</span>
        <q-separator size="0" class="q-my-lg"></q-separator>
        <q-spinner-hourglass color="orange-8" size="5em" />
        <div class="row justify-center">
        <q-skeleton animation="pulse-x" rounded class="q-mt-md" width="25%" />
        </div>
    </div>
</template>

<script>
export default {
    props: ['loading'],
}
</script>

<style scoped lang="scss">
</style>