<template>
  <q-page padding class="bg-primary">
    <div class="row item-start">
      <div class="absolute-center">
        <p class="text-h4 text-center text-grey-5">HQ ALA-15V</p>
        <p class="text-subtitle text-center text-grey-5">Cuartel General del ALA-15V Virtual</p>
        <div class="text-center">
            <q-circular-progress
            indeterminate
            size="90px"
            :thickness="0.2"
            color="secondary"
            track-color="transparent"
            show-value
            font-size="10px"
            class="q-ma-md"
            >
            <q-avatar size="60px">
                <img src="@/assets/logo_ala15.png">
            </q-avatar>
            </q-circular-progress>
            <p class="text-subtitle text-center text-grey-5">Comprobando acceso ...</p>
        </div>
      </div>
    </div>
  </q-page>    
</template>


<script>
export default {
    data() {
        return {

        }
    },
    computed: {
        isAuthenticated() {
            return this.$userStore.isAuthenticated
        }
    },
    mounted() {
        // Check local storage in order to avoid unnecessary discord login
        if (localStorage.access_token) {
            this.$userStore.refreshToken(this)
                .then(() => {
                    if (this.isAuthenticated) {   
                        return this.$userStore.user(this)
                    }
                })
                .then(() => {
                    this.$router.push({ name: 'landing' })
                })
                .catch(error => {
                    console.log(error)
                })
        }
        else {
            if (this.isAuthenticated) {            
                this.$router.push({ name: 'landing' })
            } else {            
                this.$userStore.login(this)
                    .then(() => {
                        return this.$userStore.user(this)
                    })
                    .then(() => {
                        this.$router.push({ name: 'landing' })           
                    })
                    .catch((error) => {
                        console.log('ERROR on AuthView')
                        console.log(error)
                        this.$router.push({ name: 'login' })
                    })
                // this.$router.push({ name: 'login' })
            }
        }
    },
    methods: {
    }
}
</script>