<template>
    <q-card class="q-ma-xs col-md col-sm-12 col-xs-12">
        <q-card-section horizontal class="items-center">
            <q-card-section><q-icon :name="icon" color="orange-10" size="lg" /></q-card-section>
            <q-card-section class="text-subtitle1">
            <span class="q-ma-none">{{ label }}</span>
            <q-separator></q-separator>
            <span class="q-ma-none text-h6 font-bold">{{ info }} {{ info_label }}</span>
            </q-card-section>
        </q-card-section>
    </q-card>
</template>

<script>
export default {
    props: ["icon", "label", "info", "info_label"]
}
</script>