import { flattenGraphQL } from "../utils"

class DCSmoduleModel {
    constructor() {
        this.base_url = import.meta.env.VITE_DIRECTUS_URL
    }
    queries() {
        return {
            getModuleImageIDByName: `query getModuleImageIDByName($slmod_module_name: String){
                dcs_modules(filter: {slmod_module_name: { _eq: $slmod_module_name}}) {
                    name
                    image {
                        id
                    }
                }
            }`,
            getModulesImages: `query getDCSModule($modules: [String]){
                dcs_modules(filter: {slmod_module_name: { _in: $modules}}) {
                    slmod_module_name
                    image {
                        id
                    }
                }
            }
            `            
        }
    }
    async getModulesImages(app, modules = []) {
        const store = app.$userStore
        const response = await app.$axios.post('/graphql', {
            query: this.queries().getModulesImages,
            variables: {
                modules: modules
            }
        })
        const imageCollection = Object()
        response.data.data.dcs_modules.forEach(element => {
            imageCollection[element.slmod_module_name] = `${this.base_url}assets/${element.image.id}?access_token=${store.access_token}`
        });
        return imageCollection
    }

}

export default new DCSmoduleModel()