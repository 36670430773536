import { defineStore } from "pinia";
import AuthService from '@/services/auth.service.js'
import avatarPlaceHolder from '@/assets/placeholder_pilot.png'
import { getDirectusUser } from '@/models'

export const userStore = defineStore('user', {
    state: () => {
        return {
            access_token: null,
            refresh_token: null,
            expires: null,
            isAuthenticated: false,
            pilot_name: null,
            user_id: null,
            email: null, 
            avatar: null,           
            ucid: null,
            id: null,
            role: null
        }
    },
    getters: {
        getAvatarURL: (state) => {
            const base_url = import.meta.env.VITE_DIRECTUS_URL
            if (state.avatar != null) return `${base_url}assets/${state.avatar}?access_token=${state.access_token}`
            else return avatarPlaceHolder
        }
    },
    actions: {
        async login(app) {
            let auth = new AuthService(app)
            return auth.login().then(result => {            
                this.isAuthenticated = true
                this.access_token = result.access_token
                this.refresh_token = result.refresh_token
                this.expires = result.expires
                return Promise.resolve(result)
            })
        },
        async refreshToken(app) {
            let auth = new AuthService(app)
            return auth.refresh().then(result => {
                this.isAuthenticated = true
                this.access_token = result.access_token
                this.refresh_token = result.refresh_token
                this.expires = result.expires
                return Promise.resolve(result)
            })
            .catch(() => {
                auth.logout()
            })
            
        },
        async user(app) {
            return getDirectusUser(app).then(result => {
                this.pilot_name = result.pilot_name
                this.user_id = result.id
                this.email = result.email
                this.ucid = result.dcs_ucid
                this.avatar = result.avatar
                this.id = result.id
                this.role = result.role
                return Promise.resolve(result)
            })
            .catch(error => {
                console.log(error)
                return Promise.resolve(error)
            })
        },
        async logout(app) {
            let auth = new AuthService(app)
            return auth.logout().then(result => {
            })            
        }
    }
})


