<template>
    <q-drawer
        v-model="drawer"
        show-if-above

        :mini="miniState"
        @mouseover="miniState = false"
        @mouseout="miniState = true"

        :width="250"
        :breakpoint="500"
        bordered
        class="bg-grey-3"
        mini-to-overlay
        v-if="ucid"
    >
        <q-scroll-area class="fit">
        <q-list padding>
            <q-item clickable v-ripple :to="{name: 'landing'}">
            <q-item-section avatar>
                <q-icon name="fa-solid fa-house" />
            </q-item-section>
            <q-item-section>
                Inicio
            </q-item-section>
            </q-item>

            <!-- <q-item clickable v-ripple :to="{name: 'statsModules'}">
            <q-item-section avatar>
                <q-icon name="fa-solid fa-tower-cell" />
            </q-item-section>
            <q-item-section>
                Noticias
            </q-item-section>
            </q-item> -->

            <q-item clickable v-ripple :to="{name: 'statsSelect'}">
            <q-item-section avatar>
                <q-icon name="fa-solid fa-chart-pie" />
            </q-item-section>
            <q-item-section>
                Global Stats
            </q-item-section>
            </q-item>

            <q-item clickable v-ripple :to="{name: 'topStats'}">
            <q-item-section avatar>
                <q-icon name="fa-solid fa-chart-line" />
            </q-item-section>
            <q-item-section>
                Top Stats
            </q-item-section>
            </q-item>

            <q-item clickable v-ripple :to="{name: 'pilotsMain'}">
            <q-item-section avatar>
                <q-icon name="fa-solid fa-users" />
            </q-item-section>
            <q-item-section>
                Pilotos
            </q-item-section>
            </q-item>


            <q-separator />

            <q-item clickable v-ripple @click="this.$emit('logout')">
            <q-item-section avatar>
                <q-icon name="fa-solid fa-arrow-right-from-bracket" />
            </q-item-section>

            <q-item-section>
                Salir
            </q-item-section>
            </q-item>
        </q-list>
        </q-scroll-area>
    </q-drawer>
</template>

<script>
import { userStore } from '../store'
import { mapState } from 'pinia'

export default {
    props: ['drawer'],
    data() {
        return {
            miniState: true
        }
    },
    computed: {
    ...mapState(userStore, ['ucid']),
  },
}
</script>