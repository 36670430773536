import { flattenGraphQL } from "../utils"
import { orderBy } from 'lodash';

class SlmodComposedModel {
    constructor() {
        this.base_url = import.meta.env.VITE_DIRECTUS_URL
    }
    queries() {
        return {
            getPilotInfoType1ByUCID: `query slmodMain($ucid: String!){
                slmod_modules (
                    filter: {
                        slmod_main: {
                            ucid: {
                                _eq: $ucid
                            }
                        }
                    },
                    sort: ["-time_inair"]
                ) {
                    module_name
                    time_inair
                }
                slmod_modules_event (
                    filter: {
                        slmod_main: {
                            ucid: {
                                _eq: $ucid
                            }
                        }
                    },
                    sort: ["-joined_at"]
                ) {
                   events {
                       name
                       event_date
                       event_map
                       event_editor {
                           pilot_name
                       }
                   }
                   slmod_main {
                       ucid
                   }
                   module_name
                   losses_death
                   losses_crash
                   losses_eject
                   losses_error
                   time_inair
                }
                slmod_modules_event_aggregated (
                    groupBy: ["slmod_main"],
                    filter: {
                        slmod_main: {
                            ucid: {
                                _eq: $ucid
                            }
                        }
                    },
                ) {
                    group
                    sum {
                        time_inair
                    }
                }
                slmod_modules_aggregated (
                    groupBy: ["slmod_main"],
                    filter: {
                        slmod_main: {
                            ucid: {
                                _eq: $ucid
                            }
                        }
                    }
                ) {
                    group
                    sum {
                        time_inair
                    }
                }
            }`,
            topModules: `query topFlightModules {
                slmod_modules_aggregated (
                    groupBy: ["module_name"]
                ) {
                    group
                    sum {
                        time_inair
                    }
                }
            }`,
            topWeapons: `query topFriendlyWeapons {
                slmod_modules_kills_aggregated (
                    groupBy: ["weapon"]
                ) {
                    group
                    sum {
                        kills
                    }
                }
            }`,
            topKillers: `query topEnemyKillers {
                slmod_modules_death_aggregated (
                    groupBy: ["unit_name", "weapon"],
                ) {
                    group
                    sum {
                        hits
                    }
                }
            }`,
            topModulesEvent: `query topFlightModules {
                slmod_modules_event_aggregated (
                    groupBy: ["module_name"]
                ) {
                    group
                    sum {
                        time_inair
                    }
                }
            }`,
            topWeaponsEvent: `query topFriendlyWeapons {
                slmod_modules_kills_event_aggregated (
                    groupBy: ["weapon"]
                ) {
                    group
                    sum {
                        kills
                    }
                }
            }`,
            topKillersEvent: `query topEnemyKillers {
                slmod_modules_death_event_aggregated (
                    groupBy: ["unit_name", "weapon"],
                ) {
                    group
                    sum {
                        hits
                    }
                }
            }`,
        }
    }
    async getPilotInfoType1(app, ucid) {
        try {
            const response = await app.$axios.post('/graphql', {
                query: this.queries().getPilotInfoType1ByUCID,
                variables: {
                    ucid: ucid
                }
            })
            const payload = response.data
            let time_inair = 0
            let events_time_inair = 0

            // Clean data
            if (payload.data.slmod_modules_aggregated.length > 0) time_inair = payload.data.slmod_modules_aggregated[0].sum.time_inair
            if (payload.data.slmod_modules_event_aggregated.length > 0) events_time_inair = payload.data.slmod_modules_event_aggregated[0].sum.time_inair

            // Ready for the result
            const result = {
                modules: payload.data.slmod_modules,
                events: payload.data.slmod_modules_event,
                events_time_inair: events_time_inair,
                time_inair: time_inair
            }

            return result

        } catch(error) {
            console.log('ERROR on SlmodComposedModel.getPilotInfoType1')
            console.error(error)
        }
    }
    async getTopModules(app) {
        try {
            const response = await app.$axios.post('/graphql', {
                query: this.queries().topModules
            })
            const payload = response.data
            let result = flattenGraphQL(payload)

            if (result.values) {
                return orderBy(result.values, ['time_inair'], ['desc']).slice(0, 5)
                
            } else {
                return false
            }

        } catch(error) {
            console.log('ERROR on SlmodComposedModel.getTopModules')
            console.log(error)
            return false
        }
    }
    async getTopWeapons(app) {
        try {
            const response = await app.$axios.post('/graphql', {
                query: this.queries().topWeapons
            })
            const payload = response.data
            let result = flattenGraphQL(payload)

            if (result.values) {
                return orderBy(result.values, ['kills'], ['desc']).slice(0, 5)
                
            } else {
                return false
            }

        } catch(error) {
            console.log('ERROR on SlmodComposedModel.getTopWeapons')
            console.log(error)
            return false
        }
    }
    async getTopKillers(app) {
        try {
            const response = await app.$axios.post('/graphql', {
                query: this.queries().topKillers
            })
            const payload = response.data
            let result = flattenGraphQL(payload)

            if (result.values) {
                return orderBy(result.values, ['hits'], ['desc']).slice(0, 5)
                
            } else {
                return false
            }

        } catch(error) {
            console.log('ERROR on SlmodComposedModel.getTopKillers')
            console.log(error)
            return false
        }
    }
    async getTopModulesEvent(app) {
        try {
            const response = await app.$axios.post('/graphql', {
                query: this.queries().topModulesEvent
            })
            const payload = response.data
            let result = flattenGraphQL(payload)

            if (result.values) {
                return orderBy(result.values, ['time_inair'], ['desc']).slice(0, 5)
                
            } else {
                return false
            }

        } catch(error) {
            console.log('ERROR on SlmodComposedModel.getTopModules')
            console.log(error)
            return false
        }
    }
    async getTopWeaponsEvent(app) {
        try {
            const response = await app.$axios.post('/graphql', {
                query: this.queries().topWeaponsEvent
            })
            const payload = response.data
            let result = flattenGraphQL(payload)

            if (result.values) {
                return orderBy(result.values, ['kills'], ['desc']).slice(0, 5)
                
            } else {
                return false
            }

        } catch(error) {
            console.log('ERROR on SlmodComposedModel.getTopWeapons')
            console.log(error)
            return false
        }
    }
    async getTopKillersEvent(app) {
        try {
            const response = await app.$axios.post('/graphql', {
                query: this.queries().topKillersEvent
            })
            const payload = response.data
            let result = flattenGraphQL(payload)

            if (result.values) {
                return orderBy(result.values, ['hits'], ['desc']).slice(0, 5)
                
            } else {
                return false
            }

        } catch(error) {
            console.log('ERROR on SlmodComposedModel.getTopKillers')
            console.log(error)
            return false
        }
    }    
}

export default new SlmodComposedModel()