<template>
    <q-page padding>
    <Loading :loading="loading" />
    <template v-if="!loading">
      <!-- <div class="">
        <q-banner rounded class="bg-warning text-grey-10">
          <template v-slot:avatar>
            <q-icon size="xl" color="yellow-12" name="fa-solid fa-triangle-exclamation" />
          </template>
          <span class="text-subtitle1">Mensaje del ALA-15V: Próximo wipe de estadísticas en Julio</span>
        </q-banner>
      </div>       -->
      <div v-if="error" class="">
        <q-banner rounded class="bg-red text-white">
          <template v-slot:avatar>
            <q-icon size="xl" color="red-1" name="fa-solid fa-circle-xmark" />
          </template>
          <span class="text-subtitle1">Ha ocurrido un error</span>
          <q-separator></q-separator>
          <span class="text-subtitle1">{{ error }}</span>
          <template v-slot:action>
            <q-btn flat color="white" label="Volver a entrar" :to="{name: 'login'}" />
          </template>
        </q-banner>
      </div>

      <div class="row div-container bg-yellow-2 full-width text-h4 q-ma-xs q-pa-md rounded-borders">



        <div class="col-12 full-width q-pb-md text-orange-10">
          <div class="row col-12">
            <div class="col-md-3 col-xs-12 my-career">
              <q-icon size="lg" name="fa-solid fa-id-card" />
              Mi carrera
            </div>

            <q-space />
            

            <div class="q-mt-md">
              <q-card class="col-md-3 col-xs-12">
                <q-card-section horizontal class="items-center">
                  <q-card-section class=""><q-icon name="fa-solid fa-jet-fighter" color="orange-10" size="lg" /></q-card-section>
                  <q-card-section class="text-subtitle1">
                    <span class="q-ma-none">Horas de vuelo totales</span>
                    <q-separator></q-separator>
                    <span class="q-ma-none text-h6 font-bold">{{ (totalAirHours / 3600).toFixed(2) }} horas</span>
                  </q-card-section>
                </q-card-section>
              </q-card>
            </div>

          </div>

          <q-separator size="0"></q-separator>


          <span class="text-subtitle2 q-ma-none">Tu progresión dentro del ALA-15V</span>

          <q-separator class="q-ma-none" size="0"></q-separator>

          <span class="text-caption q-ma-none">Última actualización: {{ lastUpdate }}</span>


        </div>


        <!-- PARTE CENTRAL -->

        <div class="row col-12 q-pa-none content">
          
          <div class="col-md-4 col-xs-12">
            <q-card class="no-margin bg-grey-2 q-card-css">
              <q-card-section>
                <q-img :src="moduleImages[favoriteModule]" :placeholder-src="imagePlaceHolder" fit="cover" class="q-card-css-img">
                  <div class="absolute-top text-h6 text-center text-bold">
                      Tu favorito: {{ favoriteModule }}
                  </div>
                </q-img> 
              </q-card-section>
            </q-card>
          </div> 
          <div class="col-md-4 col-xs-12">
            <q-list class="rounded-borders">
              <q-item-label header>Top 10 Módulos</q-item-label>
              <q-scroll-area style="height: 450px" visible :bar-style="{ borderRadius: '5px', background: 'grey', opacity: 0 }">
              <template v-for="(module, index) in top10Modules" :key="index">
              <q-item class="tag-list">
                <q-item-section avatar>
                  <q-avatar size="50px" rounded>
                    <img :src="moduleImages[module.module_name] || imagePlaceHolder">
                  </q-avatar>
                </q-item-section>
                <q-item-section>
                  <q-item-label lines="1" class="text-subtitle2">{{ (index+1) }}. {{ module.module_name }}</q-item-label>
                </q-item-section>
                <q-item-section class="text-subtitle2 text-right">
                  {{ (module.time_inair / 3600).toFixed(2) }} horas
                </q-item-section>
              </q-item>
              </template>
              </q-scroll-area>
            </q-list>
            <q-separator class="mobile-only" spaced></q-separator>
          </div>

          <q-space />
          
          <div class="col-md-4 col-xs-12">
            <q-list class="rounded-borders">
              <q-item-label header>Top 10 Armamento</q-item-label>
              <q-scroll-area style="height: 450px" visible>
              <template v-for="(weapon, index) in top10Weapons" :key="index">
              <q-item class="tag-list">
                <q-item-section avatar>
                  <q-avatar size="50px" rounded>
                    <img src="@/assets/placeholder_image.png">
                  </q-avatar>
                </q-item-section>
                <q-item-section>
                  <q-item-label lines="1" class="text-subtitle2">{{ weapon.weapon }}</q-item-label>
                </q-item-section>
                <q-item-section class="text-subtitle2 text-right">
                  {{ weapon.kills }} kills
                </q-item-section>
              </q-item>
              </template>
              </q-scroll-area>
            </q-list>
            <q-separator class="mobile-only" spaced></q-separator>
          </div>
          

        </div>
      </div>
    </template>
  </q-page>
</template>

<script>
import { getModulesTimeAirByUCID, dcs_modules, module_kills } from "../models"
import { userStore } from '../store'
import  Loading from '../components/Loading.vue'
import { mapState } from "pinia"
import imagePlaceHolder from '@/assets/placeholder_image.png'

export default {
  components: {
    Loading
  },
  data() {
    return {
      loading: true,
      top10Modules: null,
      top10Weapons: null,
      favoriteModule: null,
      moduleImages: null,
      imagePlaceHolder: imagePlaceHolder,
      totalAirHours: null,
      pilot_name_purposed: null,
      sendNickButtonLoading: false,
      sendNickControlsLocked: false,
      error: null
    }
  },
  computed: {
    ...mapState(userStore, ['ucid', 'email']),
    lastUpdate() {
      let datetime = new Date().toLocaleString()
      return datetime
    },
  },
  created() {
    
  },
  mounted() {
    this.loading = true
    if (!this.ucid) this.$router.push({ name: 'register' })
    if (this.ucid) {
      getModulesTimeAirByUCID(this, this.ucid).then(result => {
          if (result.values.length > 0) {
            this.favoriteModule = result.values[0].module_name
            this.top10Modules = result.values.slice(0, 10)

            result.values.forEach(element => {
              this.totalAirHours += element.time_inair
            })
          } 
          return this.getDcsModules(this.top10Modules)
        })
        .then(result => {
          this.moduleImages = result
          return module_kills.getWeaponKillsByUCID(this, this.ucid)
        })
        .then(result => {
          if (result.values.length >0) {
            this.top10Weapons = result.values.slice(0, 10)
          }
        })
        .catch(error => {
          this.error = error
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  methods: {
    getDcsModules(module) {
      let queryModules = []
      module.forEach(element => {
        queryModules.push(element.module_name)
      });
      return dcs_modules.getModulesImages(this, queryModules)
    }
  }
}
</script>


<style scoped lang="scss">
.my-card {
  border: 2px solid $yellow-9;
  width: 100%;
}
.inside-card {
    width: 100%;
}

.div-container {
  border: 2px solid $yellow-9;
}
.tag-list{
  margin-left: 10px;
  margin-bottom: 20px;
  margin-right: 10px;
  border-radius: 15px;
  background: #fff59d;
  box-shadow: 5px 5px 5px #ffa500;

}
.q-card-css{
  height: 480px;

}
.q-card-css-img{
  height: 450px

}

.my-career{
  padding-top: 15px
}
.content{
  border: 2px solid orange;
}
</style>