import { flattenGraphQL } from "../utils"
import { orderBy } from "lodash"

class EventModel {
    constructor() {
        this.base_url = import.meta.env.VITE_DIRECTUS_URL
    }
    queries() {
        return {
            lastEventByUCID: `query EventByUCID($ucid: String){
                slmod_modules_event(
                    filter: {
                        slmod_main: {
                            ucid: {
                                _eq: $ucid
                            }
                        }
                    },
                    sort: ["-events.event_date"],
                    limit: 1
                ) {
                    slmod_main {
                        name
                        ucid
                    }
                    events {
                        name
                        event_date
                    }
                }
            }`,
            lastFiveEventsByUCID: `query EventByUCID($ucid: String){
                slmod_modules_event(
                    filter: {
                        slmod_main: {
                            ucid: {
                                _eq: $ucid
                            }
                        }
                    },
                    sort: ["-events.event_date"],
                    limit: 5
                ) {
                    slmod_main {
                        name
                        ucid
                    }
                    module_name
                    events {
                        name
                        event_date
                        event_editor {
                            pilot_name
                        }
                    }
                }
            }
            `,
            assistance: `query assistance($ucid: String){
                slmod_modules_event_aggregated(
                    groupBy: ["slmod_main"],
                    filter: 
                    {
                        slmod_main: 
                        {
                            ucid: {
                                _eq: $ucid
                            }
                        }
                    }) {
                    group
                    count {
                        events
                    }
                }
            }
            `,
            survival: `query EventByUCID($ucid: String){
                slmod_modules_event_aggregated(
                    groupBy: ["slmod_main"]
                    filter: {
                        slmod_main: {
                            ucid: {
                                _eq: $ucid
                            }
                        },
                        losses_crash: {
                            _eq: 0
                        },
                        losses_death: {
                            _eq: 0
                        },
                        losses_eject: {
                            _eq: 0
                        },
                        losses_error: {
                            _eq: 0
                        }
                    },
                ) {
                    group
                    count {
                        events
                    }
                }
            }`,
            survivalEvents: `query EventByUCID($ucid: String){
                slmod_modules_event(
                    filter: {
                        slmod_main: {
                            ucid: {
                                _eq: $ucid
                            }
                        },
                        losses_crash: {
                            _eq: 0
                        },
                        losses_death: {
                            _eq: 0
                        },
                        losses_eject: {
                            _eq: 0
                        },
                        losses_error: {
                            _eq: 0
                        }
                    },
                    sort: ["-events.event_date"],
                    limit: 5
                ) {
                    module_name
                    events {
                        name
                        event_date,
                        event_editor {
                            pilot_name
                        }
                    }
                }
            }`,
            kills: `query EventByUCID($ucid: String){
                slmod_modules_kills_event_aggregated(
                    groupBy: ["slmod_modules_event"],
                    filter: {
                        slmod_modules_event: {
                            slmod_main: {
                                ucid: {
                                    _eq: $ucid
                                }
                            }
                        }
                    }
                ) {
                    group
                    sum {
                        kills
                    }
                }
            }`,
            deaths: `query EventByUCID($ucid: String){
                slmod_modules_death_event_aggregated(
                    groupBy: ["slmod_modules_event"],
                    filter: {
                        slmod_modules_event: {
                            slmod_main: {
                                ucid: {
                                    _eq: $ucid
                                }
                            }
                        }
                    }
                ) {
                    group
                    sum {
                        hits
                    }
                }
            }`,
            killList: `query EventByUCID($ucid: String){
                slmod_modules_killlist_event(
                    filter: {
                        slmod_modules_kills_event: {
                            slmod_modules_event: {
                                slmod_main: {
                                    ucid: {
                                        _eq: $ucid
                                    }
                                }
                            }
                        }
                    },
                    sort: ["-slmod_modules_kills_event.kills"],
                    limit: 5
                ) {
                    unit_name
                    slmod_modules_kills_event {
                        weapon
                        kills
                        slmod_modules_event {
                            module_name
                        }
                    }
                }
            }`,
            flightHours: `query EventByUCID($ucid: String){
                slmod_modules_event_aggregated(
                    groupBy: ["slmod_main"],
                    filter: {
                        slmod_main: {
                            ucid: {
                                _eq: $ucid
                            }
                        }
                    }
                ) {
                    group
                    sum {
                        time_inair
                    }
                }
            }`,
            flightHoursLastFiveEvents: `query EventByUCID($ucid: String){
                slmod_modules_event(
                    filter: {
                        slmod_main: {
                            ucid: {
                                _eq: $ucid
                            }
                        }
                    },
                    sort: ["-events.event_date"],
                    limit: 5
                ) {
                    slmod_main {
                        name
                        ucid
                    }
                    module_name
                    time_inair
                    events {
                        name
                        event_date
                        event_editor {
                            pilot_name
                        }
                    }
                }
            }`,
        }
    }
    async getLastEventByUCID(app, ucid) {
        try {
            const response = await app.$axios.post('/graphql', {
                query: this.queries().lastEventByUCID,
                variables: {
                    ucid: ucid
                }
            })
            const payload = response.data
            let result = flattenGraphQL(payload)       

            return result

        } catch(error) {
            console.log('ERROR on getLastEventByUCID')
            console.error(error)
        }
    }
    async getLastFiveEvenstByUCID(app, ucid) {
        try {
            const response = await app.$axios.post('/graphql', {
                query: this.queries().lastFiveEventsByUCID,
                variables: {
                    ucid: ucid
                }
            })
            const payload = response.data
            let result = flattenGraphQL(payload)       

            return result

        } catch(error) {
            console.log('ERROR on getLastFiveEvenstByUCID')
            console.error(error)
        }
    }
    async getAssistanceByUCID(app, ucid) {
        try {
            const response = await app.$axios.post('/graphql', {
                query: this.queries().assistance,
                variables: {
                    ucid: ucid
                }
            })
            const payload = response.data
            let result = flattenGraphQL(payload)       

            return result

        } catch(error) {
            console.log('ERROR on getAssistanceByUCID')
            console.error(error)
        }
    }
    async getSurvivalByUCID(app, ucid) {
        try {
            const response = await app.$axios.post('/graphql', {
                query: this.queries().survival,
                variables: {
                    ucid: ucid
                }
            })
            const payload = response.data
            let result = flattenGraphQL(payload)

            if (result.values.length > 0) return result
            else return false

        } catch(error) {
            console.log('ERROR on getSurvivalByUCID')
            console.error(error)        
        }
    }
    async getSurvivalEventsByUCID(app, ucid) {
        try {
            const response = await app.$axios.post('/graphql', {
                query: this.queries().survivalEvents,
                variables: {
                    ucid: ucid
                }
            })
            const payload = response.data
            let result = flattenGraphQL(payload)

            if (result.values.length > 0) return result
            else return false

        } catch(error) {
            console.log('ERROR on getSurvivalEventsByUCID')
            console.error(error)        
        }
    }
    async getKillsByUCID(app, ucid) {
        try {
            const response = await app.$axios.post('/graphql', {
                query: this.queries().kills,
                variables: {
                    ucid: ucid
                }
            })
            const payload = response.data
            let result = flattenGraphQL(payload)

            if (result.values.length > 0) {
                let total_kills = 0
                result.values.forEach(element => {
                    total_kills += element.kills
                })
                return total_kills
            }
            else return false

        } catch(error) {
            console.log('ERROR on getKillsByUCID')
            console.error(error)        
        }
    }
    async getDeathsByUCID(app, ucid) {
        try {
            const response = await app.$axios.post('/graphql', {
                query: this.queries().deaths,
                variables: {
                    ucid: ucid
                }
            })
            const payload = response.data
            let result = flattenGraphQL(payload)

            if (result.values.length > 0) {
                let total_deaths = 0
                result.values.forEach(element => {
                    total_deaths += element.hits
                })
                return total_deaths
            }
            else return false

        } catch(error) {
            console.log('ERROR on getDeathsByUCID')
            console.error(error)        
        }
    }
    async getKillListByUCID(app, ucid) {
        try {
            const response = await app.$axios.post('/graphql', {
                query: this.queries().killList,
                variables: {
                    ucid: ucid
                }
            })
            const payload = response.data
            let result = flattenGraphQL(payload)

            if (result.values.length > 0) return result
            else return false

        } catch(error) {
            console.log('ERROR on getKillListByUCID')
            console.error(error)        
        }
    }
    async getFlightHoursByUCID(app, ucid) {
        try {
            const response = await app.$axios.post('/graphql', {
                query: this.queries().flightHours,
                variables: {
                    ucid: ucid
                }
            })
            const payload = response.data
            let result = flattenGraphQL(payload)

            if (result.values.length > 0) return (result.values[0].time_inair / 3600).toFixed(2)
            else return false

        } catch(error) {
            console.log('ERROR on getFlightHoursByUCID')
            console.error(error)        
        }
    }
    async getFlightHoursLastFiveEventByUCID(app, ucid) {
        try {
            const response = await app.$axios.post('/graphql', {
                query: this.queries().flightHoursLastFiveEvents,
                variables: {
                    ucid: ucid
                }
            })
            const payload = response.data
            let result = flattenGraphQL(payload)

            if (result.values.length > 0) return result
            else return false

        } catch(error) {
            console.log('ERROR on getFlightHoursLastEventByUCID')
            console.error(error)        
        }
    }      
}

export default new EventModel()