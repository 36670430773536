import { flattenGraphQL } from "../utils"

class SlmodMainModel {
    constructor() {
        this.base_url = import.meta.env.VITE_DIRECTUS_URL
    }
    queries() {
        return {
            getAll: `query slmodMain($page: Int!, $limit: Int!, $search: String!){
                slmod_main(
                    limit: $limit,
                    page: $page,
                    search: $search,
                    sort: ["name"],
                    filter: {
                        name: { _starts_with: "=ALA15V="}
                    }
                ) {
                    id
                    name
                    ucid
                }
            }`,           
        }
    }
    async getAll(app, search='', limit=100, page=1) {
        try {
            const response = await app.$axios.post('/graphql', {
                query: this.queries().getAll,
                variables: {
                    limit: limit,
                    page: page,
                    search: search
                }
            })
            const payload = response.data
            let result = flattenGraphQL(payload)       

            return result

        } catch(error) {
            console.log('ERROR on SlmodMainModel.getAll')
            console.error(error)
        }
    }

}

export default new SlmodMainModel()