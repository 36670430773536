import axios from 'axios';
import authHeader from './auth-header'

class AuthService {
    constructor(vueApp) {
        this.app = vueApp
    }
    login() {
        return this.app.$axios.post('/auth/refresh', {
            mode: 'json'
        }, {
            withCredentials: true
        })
        .then(response => {
            if (response.data.data.access_token) {
                const payload = response.data.data
                localStorage.setItem('access_token', payload.access_token)
                localStorage.setItem('refresh_token', payload.refresh_token)
                localStorage.setItem('expires', payload.expires)

                this.app.$axios.defaults.headers.common['Authorization'] = `Bearer ${payload.access_token}`

                return payload
            }
        })
    }
    logout() {
        return this.app.$axios.post('/auth/logout', {
            refresh_token: localStorage.getItem('refresh_token')
        })
        .finally(() => {
            // Clear pinia user store data
            this.app.$userStore.$reset()
            localStorage.removeItem('access_token')
            localStorage.removeItem('refresh_token')
            localStorage.removeItem('expires')
            
            this.app.$router.push({ name: 'login' })
        })
    }
    refresh() {
        delete this.app.$axios.defaults.headers.common['Authorization']
        return this.app.$axios.post('/auth/refresh', {
            refresh_token: localStorage.getItem('refresh_token')
        })
        .then(response => {
            const payload = response.data.data
            localStorage.setItem('access_token', payload.access_token)
            localStorage.setItem('refresh_token', payload.refresh_token)
            localStorage.setItem('expires', payload.expires)

            this.app.$axios.defaults.headers.common['Authorization'] = `Bearer ${payload.access_token}`

            return payload
        })
    }
}

export default AuthService