import { createApp, resolveTransitionHooks } from 'vue'
import App from './App.vue'
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import router from './router'
import { userStore } from './store'
import VueCookies from 'vue-cookies'
import { Quasar } from 'quasar'
import quasarLang from 'quasar/lang/es'
import quasarIconSet from 'quasar/icon-set/fontawesome-v6'
import { createPinia } from 'pinia'
import { responseRefreshToken, responseHandlerError } from './services/interceptors';
import VueGtag from "vue-gtag";

// Import icon libraries
import '@quasar/extras/material-icons/material-icons.css'
// ..required because of selected iconSet:
import '@quasar/extras/fontawesome-v6/fontawesome-v6.css'

// Import Quasar css
import 'quasar/src/css/index.sass'

// Import axios
import axios from 'axios';

const app = createApp(App)

// Enable sentry on production only
if (import.meta.env.PROD) {
  Sentry.init({
    app,
    dsn: 'https://6052b20c803c4f8c8657b581c4e4b557@o1246767.ingest.sentry.io/6406406',
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ["localhost", "localhost:3000", "sentry.tux-es.com", "hq.ala15v.com", /^\//],
      }),
    ],
    tracesSampleRate: 1.0,
    trackComponents : true,
    release: 'hqala15@' + (import.meta.env.VITE_BUILD_VERSION || 'local'),
    attachProps: true,
    attachStacktrace: true,
    environment: import.meta.env.MODE,
  });
}

app.use(createPinia())
app.use(router)
app.use(VueCookies)

app.use(VueGtag, {
  config: { id: "G-QMBR7LJBWJ" }})

app.use(Quasar, {
    plugins: {}, // import Quasar plugins and add here
    lang: quasarLang,
    iconSet: quasarIconSet,
  })
  
app.config.globalProperties.$appName = 'hqala15v';

const axiosInstance = axios.create({
  baseURL: import.meta.env.VITE_DIRECTUS_URL,
  headers: {
    "Content-Type": "application/json",
  },
  timeout: 10000,
});

app.config.globalProperties.$axios = axiosInstance;
app.provide('$axios', axiosInstance)

app.config.globalProperties.$userStore = userStore()

app.config.globalProperties.$axios.interceptors.response.use(
  (response) => responseRefreshToken(response, axiosInstance),
  (error) => responseHandlerError(error, axiosInstance, router)
);

app.mount('#app')
