import { flattenGraphQL } from "../utils"

class SlmodModulesModel {
    constructor() {
        this.base_url = import.meta.env.VITE_DIRECTUS_URL
    }
    queries() {
        return {
            getAll: `query slmodModules($page: Int!, $limit: Int!, $search: String!){
                slmod_modules(
                    limit: $limit,
                    page: $page,
                    search: $search,
                    sort: ["-time_inair"],
                    filter: {
                        slmod_main: {
                            name: { _starts_with: "=ALA15V="}
                        }
                    }
                ) {
                    id
                    slmod_main {
                        name
                    }
                    module_name
                    landings
                    takeoffs
                    time_inair
                    lost_to_aaa
                    lost_to_sam
                }
            }`,           
        }
    }
    async getAll(app, search='', limit=100, page=1) {
        try {
            const response = await app.$axios.post('/graphql', {
                query: this.queries().getAll,
                variables: {
                    limit: limit,
                    page: page,
                    search: search
                }
            })
            const payload = response.data
            let result = flattenGraphQL(payload)       

            return result

        } catch(error) {
            console.log('ERROR on getAll')
            console.error(error)
        }
    }

}

export default new SlmodModulesModel()