<template>
    <q-page padding>
    <Loading :loading="loading" />
    <template v-if="!ucid">
      <div class="">
        <div class="row div-container bg-yellow-2 full-width row text-h4 q-ma-xs q-pa-md rounded-borders">
          <p class="text-h4 col-12 text-orange-10">
          <q-icon size="lg" name="fa-solid fa-id-card" />
          Progreso de registro
          </p>
          <q-card class="col-12 bg-yellow-3">
          <q-card-section>
            <div class="q-gutter-md">
            <q-input :disable="sendNickControlsLocked" color="orange-10" v-model="nick_purposed" class="col-6 text-subtitle1 text-orange-10" label-color="orange-10" label="Escribe tu nick de ALA-15V con el tag =ALA15V=">
              <template v-slot:prepend>
                <q-icon color="orange-10" name="fa-solid fa-user" />
              </template>
            </q-input>
            </div>
          </q-card-section>
          <q-card-actions>
            <q-btn :loading="sendNickButtonLoading" @click="sendNickClick" :disable="sendNickControlsLocked" color="orange-10" icon="fa-solid fa-cloud-arrow-up" class="q-ma-none" label="Enviar" />
          </q-card-actions>
          <q-card-section v-if="sendNickMessage" >
            <q-banner rounded class="bg-positive text-grey-10">
                <template v-slot:avatar>
                    <q-icon size="md" color="white" name="fa-regular fa-envelope-open" />
                </template>
                <span class="text-subtitle1 text-white">{{ sendNickMessage }}</span>
            </q-banner>
          </q-card-section>
          <q-card-section>
          <q-separator></q-separator>
          <q-linear-progress size="50px" stripe :value="0.50" color="orange-8" class="q-mt-sm">
            <div class="absolute-full flex flex-center">
              <span class="text-primary text-h5" label="50%">50%</span>
            </div>
          </q-linear-progress>
          <ul class="text-subtitle1 text-orange-10">
          <li>Tenemos que registrarte como piloto del ALA-15V</li>
          <li>Tenemos que darte permisos</li>
          <li>Tenemos que vincular tus estadísticas</li>
          </ul>
          </q-card-section>
          </q-card>
        </div>
      </div>      
    </template>
    <template v-else>
    </template>
  </q-page>
</template>

<script>
import { userStore } from '../store'
import  Loading from '../components/Loading.vue'
import { mapState } from "pinia"

export default {
  components: {
    Loading
  },
  data() {
    return {
      loading: false,
      nick_purposed: null,
      sendNickButtonLoading: false,
      sendNickControlsLocked: true,
      sendNickMessage: null
    }
  },
  computed: {
    ...mapState(userStore, ['ucid', 'email', 'role', 'id']),
  },
  mounted() {
      this.sendNickButtonLoading = true
      this.$axios.post('graphql', {
          query: `query getRegisterControl($id: String){
                register_process_control(filter: {directus_user: {id: {_eq: $id}}}) {
                    email
                    nick_purposed
                    directus_user {
                        id
                        email
                    }
                }
            }
         `,
         variables: {
           "id": "37864a0f-bead-47bc-ab59-f4a3c615cd92"
         }
      }).then(result => {
          this.nick_purposed = result.data.data.register_process_control[0].nick_purposed
          this.sendNickControlsLocked = true
          this.sendNickMessage = `Tu registro ya está en proceso con el nick: ${this.nick_purposed}`
      }).catch(() => {
          this.sendNickControlsLocked = false
      }).finally(() => {
          this.sendNickButtonLoading = false
          
      })
  },
  methods: {
    async sendNickClick() {
        this.sendNickButtonLoading = true
        try {
            await this.$axios.post('items/register_process_control', {
                directus_user: this.id,
                email: this.email,
                nick_purposed: this.nick_purposed,
                status: 'published'
            })
            this.sendNickMessage = `Solicitud de registro enviada con el nick: ${this.nick_purposed}`
        } catch (error) {
            this.sendNickMessage = `Tu registro ya está en proceso con el nick: ${this.nick_purposed}`
        }
        this.sendNickControlsLocked = true
        this.sendNickButtonLoading = false
    }
  }
}
</script>


<style scoped lang="scss">
.my-card {
  border: 2px solid $yellow-9;
  width: 100%;
}
.inside-card {
    width: 100%;
}

.div-container {
  border: 2px solid $yellow-9;
}
</style>