import { userStore } from '../store'

export const responseRefreshToken = async (response, axiosInstance) => {
    return response
}

export const responseHandlerError = async (error, axiosInstance, router) => {
    const store = userStore()
    const originalConfig = error.config
    const app = {
        $axios: axiosInstance,
        $userStore: store,
        $router: router
    }
  
    if (originalConfig.url == '/auth/logout') return Promise.reject(error)

    if (originalConfig.url !== '/auth/refresh' && error.response) {
      // access_token was expired
      if (error.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true
        try {
          await store.refreshToken(app)
          originalConfig.headers['Authorization'] = 'Bearer ' + store.access_token;
          return axiosInstance(originalConfig)
        } catch(error) {
          console.log('ERROR en interceptor response')
          console.log(error)
          return Promise.reject(error)
        }
      }
    }
    return Promise.reject(error)
  }