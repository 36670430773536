<template>
  <q-page padding>
    <div class="row div-container bg-yellow-2 full-width row text-h4 q-ma-xs q-pa-md rounded-borders">
      <div class="col-12 full-width q-pb-md text-orange-10">
        <q-icon size="lg" name="fa-solid fa-users" />
        Pilotos del ALA-15V
        <q-separator class="q-ma-md"></q-separator>
      </div>
      <div class="row col-12 full-width q-gutter-sm">
        <div class="col-md-2 col-xs-12">
            <q-table
            :rows="rows"
            :columns="columns"
            row-key="id"
            :loading="loading"
            :visible-columns="visibleColumns"
            no-data-label="No hay datos."
            no-results-label="Sin resultados."
            loading-label="Cargando información..."
            :rows-per-page-options="[10, 25,50,100]"
            :filter="filter"
            binary-state-sort
            class="col-12"
            bordered
            dark
            table-header-class="bg-primary text-white"
            @row-click="pilotSelected"
            >
            <template v-slot:top-left>
                <q-input debounce="300" v-model="filter" placeholder="Buscar" standout="bg-primary text-white" label-color="white" color="white">
                <template v-slot:append>
                    <q-icon name="search" color="white" />
                </template>
                </q-input>
            </template>
            </q-table>      
        </div>
        <div class="col">
        <q-card>
            <q-card-section>
                <MiniLoading :loading="miniLoading" />
                <PilotsTableInfoVue :pilotName="pilotName" :pilotInfo="pilotInfo" filter="filter" />
            </q-card-section>
        </q-card>
        </div>
      </div>
    </div>
  </q-page>
</template>


<script>
import { slmod_main, slmod_composed } from '../models'
import PilotsTableInfoVue from '../components/PilotsTableInfo.vue'
import MiniLoading from '../components/MiniLoading.vue'

export default {
    components: {
        PilotsTableInfoVue,
        MiniLoading
    },
    data() {
        return {
            error: null,
            loading: true,
            miniLoading: false,
            filter: '',
            pilotName: null,
            pilotInfo: null,
            visibleColumns: [
                'name'
            ],
            columns: [
                {
                    name: 'id',
                    field: 'id',
                    required: false
                },
                {
                    name: 'name',
                    field: 'name',
                    label: 'Piloto',
                    sortable: true,
                    classes: 'text-grey-2',
                    align: 'left',
                    headerStyle: 'width: 250px'
                },
                {
                    name: 'ucid',
                    field: 'ucid'
                }
            ],
            rows: [],
        }
    },
    mounted() {
        const requests = [
            this.getAll()
        ]

        Promise.all(requests)
            .catch(error => {
                this.error = error
                console.log(error)
            })
            .finally(() => {
                this.loading = false
            })
    },
    methods: {
        async getAll() {
            const response = await slmod_main.getAll(this, this.filter, 999999, 1)
            if (response.values) {
                this.rows = response.values
            }
        },
        async getPilotInfoType1(ucid) {
            const response = await slmod_composed.getPilotInfoType1(this, ucid)
            return response
        },
        pilotSelected (event, row, index) {
            this.miniLoading = true
            this.getPilotInfoType1(row.ucid)
                .then(result => {
                    this.pilotName = row.name
                    this.pilotInfo = result
                })
                .finally(() => {
                    this.miniLoading = false
                })
            
        }
    }
}
</script>

<style scoped lang="scss">
.my-card {
  border: 2px solid $yellow-9;
}
.div-container {
  border: 2px solid $yellow-9;
}
</style>