const toHHMMSS = (secs) => {
    var sec_num = parseInt(secs, 10)
    var hours   = '0' + Math.floor(sec_num / 3600)
    var minutes = Math.floor(sec_num / 60) % 60
    var seconds = sec_num % 60
    
    if (hours == 0) hours = '00'

    return [hours,minutes,seconds]
        .map(v => v < 10 ? "0" + v : v)
        .filter((v,i) => v !== "00" || i > 0)
        .join(":")
}

function flattenObject (obj)  {
    const flattened = {}
  
    Object.keys(obj).forEach((key) => {
      const value = obj[key]
  
      if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
        Object.assign(flattened, flattenObject(value))
      } else {
        flattened[key] = value
      }
    })
    return flattened
  }

function flattenObjectArray (arr) {
    const flattened = []

    arr.forEach(element => {
        flattened.push(flattenObject(element))
    })   
    return flattened 
}

function flattenGraphQL (graphqlResponse) {
    let keys = []
    const data = graphqlResponse.data
    const main_key = Object.keys(data)[0]
    const deep_data = data[main_key]

    if (deep_data.length > 0) {
        keys = Object.keys(deep_data[0])
    }
    const flattened = flattenObjectArray(deep_data)
    return {
        keys: keys,
        values: flattened,
    }
}

export { toHHMMSS, flattenGraphQL }

