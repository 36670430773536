<template>
  <q-layout view="hHh Lpr fFf">

    <q-header elevated class="bg-primary text-white" height-hint="98">
      <q-toolbar>
        <!-- <q-btn dense flat round icon="menu" @click="toggleLeftDrawer" /> -->
        <q-btn flat @click="drawer = !drawer" round dense icon="menu" />
        <q-toolbar-title class="desktop-only">
          <q-avatar>
            <img src="@/assets/logo_ala15.png">
          </q-avatar>
          {{$route.meta.title || 'Untitled' }}
        </q-toolbar-title>
        <q-toolbar-title class="mobile-only text-caption">
          <q-avatar>
            <img src="@/assets/logo_ala15.png" @click="drawer = !drawer" clickable>
          </q-avatar>
          <!-- HQ ALA-15V -->
        </q-toolbar-title>
        <q-item clickable v-ripple>
          <q-menu>
            <q-list style="min-width: 100px">
              <q-separator />
              <q-item clickable v-close-popup>
                <q-item-section @click="logout()">
                  <q-icon name="fa-solid fa-arrow-right-from-bracket" size="md" color="primary" />
                </q-item-section>
                <q-item-section @click="logout()">
                  Salir
                </q-item-section>
              </q-item>
              <q-separator />
            </q-list>
          </q-menu>          
          <q-item-section>
            <q-item-label>{{ pilot_name || 'Sin nombre' }}</q-item-label>
            <q-item-label class="text-grey-5" caption>sin mensajes nuevos</q-item-label>
          </q-item-section>
          <q-item-section side>
            <q-avatar rounded size="48px">
              <q-img :src="getAvatarURL" fit="cover" />
            </q-avatar>
          </q-item-section>
        </q-item>
      </q-toolbar>
      <q-tabs align="left" v-if="ucid">
        <q-route-tab class="mobile-only" :to="{name: 'landing'}"  icon="fa-solid fa-house" />
        <q-route-tab class="desktop-only" :to="{name: 'landing'}"  label="Inicio" />
        <q-route-tab class="mobile-only" :to="{name: 'operations', params: { ucid: 'self', name: 'self'}}"  icon="fa-solid fa-satellite-dish" />
        <q-route-tab class="desktop-only" :to="{name: 'operations', params: { ucid: 'self', name: 'self'}}" label="Operaciones" />
        <q-route-tab class="desktop-only" :to="{name: 'statsSelect'}" label="Global" />
        <q-route-tab class="mobile-only" :to="{name: 'statsSelect'}" icon="fa-solid fa-chart-pie" />
        <q-route-tab v-if="isDevel" :to="{name: 'sandbox'}" label="sandbox" />
      </q-tabs>
    </q-header>

    <MenuLeft :drawer="drawer" @logout="logout" />

    <q-page-container class="bg-grey-4">
      <router-view />
    </q-page-container>

    <q-footer bordered class="bg-primary text-white text-center">
        <div class="q-ma-sm">2022 - ALA-15V <a href="www.ala15v.com" target="_blank" class="text-secondary">www.ala15v.com</a> (Build {{ version }})</div>
    </q-footer>

  </q-layout>
</template>

<script>
import { userStore } from '../store'
import { mapState } from 'pinia'
import MenuLeft from '../components/MenuLeft.vue'

export default {
  components: {
    MenuLeft
  },
  data() {
    return {
      isDevel: import.meta.env.DEV,
      version: import.meta.env.VITE_BUILD_VERSION || 'local',
      drawer: false,
    }
  },
  computed: {
    ...mapState(userStore, ['pilot_name', 'getAvatarURL', 'email', 'ucid']),
  },
  methods: {
      logout() {
        this.$userStore.logout(this)
      }
  }
}
</script>