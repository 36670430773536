<template>
  <q-page padding>
  <Loading :loading="loading" />
  <template v-if="!loading">
    <div v-if="error" class="">
      <q-banner rounded class="bg-red text-white">
        <template v-slot:avatar>
          <q-icon size="xl" color="red-1" name="fa-solid fa-circle-xmark" />
        </template>
        <span class="text-subtitle1">Ha ocurrido un error</span>
        <q-separator></q-separator>
        <span class="text-subtitle1">{{ error }}</span>
        <template v-slot:action>
          <q-btn flat color="white" label="Volver a entrar" :to="{name: 'login'}" />
        </template>
      </q-banner>
    </div>      
    <div class="div-container bg-yellow-2 row justify-evenly q-ma-xs q-pa-md full-height rounded-borders">
      <div class="col-12 full-width q-pb-md text-orange-10 text-h4 text-bold">
        <q-icon size="lg" name="fa-solid fa-satellite-dish" />
        Estadísticas de operaciones
        <q-separator size="0"></q-separator>
        <span v-if="!routedUcid || $route.params.name == 'self'" class="text-subtitle2">Aquí encontrarás tus estadísticas sobre la participación en los eventos oficiales del ALA-15V</span>
        <span v-else class="text-subtitle2 q-pa-sm">Estadísticas para {{ $route.params.name }}
          <q-separator size="0"></q-separator>
          <q-btn @click="$router.go(-1)">Volver</q-btn>
        </span>
        <q-separator class="q-mt-md"></q-separator>
      </div>
      <q-card class="col-md-3 col-xs-12 my-card bg-yellow-3 q-mb-md text-center">
        <q-img src="@/assets/eventos.jpg" height="200px" position="25% 0"></q-img>
        <q-card-section>
          <div class="text-h5 text-orange-8 text-bold">Asistencia</div>
          <div class="text-caption text-orange-8">Participación en eventos oficiales</div>
        </q-card-section>
        <q-card-section class="text-center section-top">
          <span class="text-orange-8 text-subtitle1">{{ lastEvent.name }}<br />({{ lastEvent.event_date }})</span>
          <q-separator size="0"></q-separator>
          <span class="text-orange-8 text-caption">Último evento</span>
        </q-card-section>
        <q-separator></q-separator>
        <q-card-section class="bg-orange-1 section-middle">
          <div class="row justify-center text-center">
          <!-- <q-icon name="fa-solid fa-arrow-trend-up" color="orange-8" size="md"></q-icon> -->
          <span class="text-orange-8 text-h5 q-ml-md">{{ assistance }} asistencias</span>
          </div>
          <div class="row justify-center text-center">
          <q-separator size="0"></q-separator>
          <span class="text-orange-8 text-overline">Total</span>
          </div>
        </q-card-section>
        <q-separator></q-separator>
        <q-card-section class="section-bottom">
          <div class="row justify-center text-center">
          <span class="text-orange-8 text-h6">Top 5</span>
          </div>
          <div class="row justify-center text-orange-8">
            <div class="text-left">
              <ul style="list-style: none" class="q-pa-none">
                <template v-for="(event, index) in lastFiveEvents" :key="index">
                <li>{{ index + 1 }}. {{ event.name }} el día {{ formatDate(event.event_date, 'dd-MM-Y') }} con el {{ event.module_name }}</li>
                </template>
              </ul>
            </div>
          </div>
        </q-card-section>        
      </q-card>
      <q-card class="col-md-3 col-xs-12 my-card bg-yellow-3 q-mb-md  text-center">
        <q-img src="@/assets/survival.jpg" height="200px" position="0 0"></q-img>
        <q-card-section>
          <div class="text-h5 text-orange-8 text-bold">Supervivencia</div>
          <div class="text-caption text-orange-8">Supervivencia en los eventos</div>
        </q-card-section>
        <q-card-section class="text-center section-top">
          <span class="text-orange-8 text-h3">{{ survivalPerc }}%</span>
          <q-separator size="0"></q-separator>
          <span class="text-orange-8 text-caption">{{ survival }} / {{ assistance }}</span>
        </q-card-section>
        <q-separator></q-separator>
        <q-card-section class="bg-orange-1 section-middle">
          <div class="row justify-center text-center">
          <q-icon v-if="survivalPerc > 0 && survivalPerc < 45" name="fa-solid fa-face-frown" color="orange-8" size="xl"></q-icon>
          <q-icon v-if="survivalPerc == 0" name="fa-solid fa-skull" color="orange-8" size="xl"></q-icon>
          <q-icon v-if="survivalPerc >= 75" name="fa-solid fa-face-grin-stars" color="orange-8" size="xl"></q-icon>
          <q-icon v-if="survivalPerc >= 45 && survivalPerc < 75" name="fa-solid fa-face-meh" color="orange-8" size="xl"></q-icon>
          </div>
          <div class="row justify-center text-center">
          <q-separator size="0"></q-separator>
          <span class="text-orange-8 text-overline">Status</span>
          </div>
        </q-card-section>
        <q-separator></q-separator>
        <q-card-section class="section-bottom">
          <div class="row justify-center text-center">
          <span class="text-orange-8 text-h6">Top 5</span>
          </div>
          <div class="row justify-center text-orange-8">
            <div class="text-left">
              <ul style="list-style: none" class="q-pa-none">
                <template v-for="(event, index) in survivalEvents" :key="index">
                <li>{{ index + 1 }}. {{ event.name }} el día {{ formatDate(event.event_date, 'dd-MM-Y') }} con el {{ event.module_name }}</li>
                </template>
              </ul>
            </div>
          </div>
        </q-card-section>            
      </q-card>
      <q-card class="col-md-3 col-xs-12 my-card bg-yellow-3 q-mb-md  text-center">
        <q-img src="@/assets/kdratio.jpg" height="200px"></q-img>
        <q-card-section>
          <div class="text-h5 text-orange-8 text-bold">K/D</div>
          <div class="text-caption text-orange-8">Kill Death Ratio</div>
        </q-card-section>
        <q-card-section class="text-center section-top">
          <span class="text-orange-8 text-h3">{{ killDeathRatio }}</span>
          <q-separator size="0"></q-separator>
          <span class="text-orange-8 text-caption">Kills: {{ kills }} / Deaths: {{ deaths }}</span>
        </q-card-section>
        <q-separator></q-separator>
        <q-card-section class="bg-orange-1 section-middle">
          <div class="row justify-center text-center">
          <q-icon v-if="killDeathRatio > 0 && killDeathRatio < 1" name="fa-solid fa-arrow-trend-down" color="orange-8" size="xl"></q-icon>
          <q-icon v-if="killDeathRatio == 1" name="fa-solid fa-arrow-right-arrow-left" color="orange-8" size="xl"></q-icon>
          <q-icon v-if="killDeathRatio > 1" name="fa-solid fa-arrow-trend-up" color="orange-8" size="xl"></q-icon>
          </div>
          <div class="row justify-center text-center">
          <q-separator size="0"></q-separator>
          <span class="text-orange-8 text-overline">Tendencia</span>
          </div>
        </q-card-section>
        <q-separator></q-separator>
        <q-card-section class="section-bottom">
          <div class="row justify-center text-center">
          <span class="text-orange-8 text-h6">Top 5</span>
          </div>
          <div class="row justify-center text-orange-8">
            <div class="text-left">
              <ul style="list-style: none" class="q-pa-none">
                <template v-for="(element, index) in killList" :key="index">
                <li>{{ index + 1 }}. {{ element.unit_name }} con {{ element.weapon  }} {{ element.kills }} {{ element.kills > 1 ? 'veces' : 'vez' }} con el {{ element.module_name }}</li>
                </template>
              </ul>
            </div>
          </div>
        </q-card-section>            
      </q-card>
      <q-card class="col-md-3 col-xs-12 my-card bg-yellow-3 q-mb-md  text-center">
        <q-img src="@/assets/logbook.png" height="200px"></q-img>
        <q-card-section>
          <div class="text-h5 text-orange-8 text-bold">Horas de vuelo</div>
          <div class="text-caption text-orange-8">Tiempo de vuelo en el aire</div>
        </q-card-section>
        <q-card-section class="text-center section-top">
          <span class="text-orange-8 text-h3">{{ flightHoursLastEvent }} h</span>
          <q-separator size="0"></q-separator>
          <span class="text-orange-8 text-caption">Último evento</span>
        </q-card-section>
        <q-separator></q-separator>
        <q-card-section class="bg-orange-1 section-middle">
          <div class="row justify-center text-center">
          <span class="text-orange-8 text-h5 q-ml-md">{{ flightHours }} horas</span>
          </div>
          <div class="row justify-center text-center">
          <q-separator size="0"></q-separator>
          <span class="text-orange-8 text-overline">Total</span>
          </div>
        </q-card-section>
        <q-separator></q-separator>
        <q-card-section class="section-bottom">
          <div class="row justify-center text-center">
          <span class="text-orange-8 text-h6">Top 5</span>
          </div>
          <div class="row justify-center text-orange-8">
            <div class="text-left">
              <ul style="list-style: none" class="q-pa-none">
                <template v-for="(element, index) in flightHoursLastFiveEvents" :key="index">
                <li>{{ index + 1 }}. Evento {{ element.name }} con el {{ element.module_name }} {{ (element.time_inair / 3600).toFixed(2) }} horas</li>
                </template>
              </ul>
            </div>
          </div>
        </q-card-section>            
      </q-card>
    </div>
    </template>
  </q-page>
</template>

<script>
import { events } from "../models"
import  Loading from '../components/Loading.vue'
import { userStore } from '../store'
import { mapState } from "pinia"
import { parseISO, format } from "date-fns"

export default {
  components: {
    Loading
  },
  computed: {
    ...mapState(userStore, ['ucid', 'email']),
    survivalPerc() {
      return ((this.survival / this.assistance)*100).toFixed(0)
    },
    killDeathRatio() {
      return (this.kills / this.deaths) == 'Infinity' ? this.kills : this.kills / this.deaths
    },
  },
  data() {
    return {
      loading: true,
      error: null,
      lastEvent: {},
      lastFiveEvents: [],
      assistance: 0,
      survival: 0,
      survivalEvents: [],
      kills: 0,
      deaths: 0,
      killList: [],
      flightHours: 0,
      flightHoursLastEvent: 0,
      flightHoursLastFiveEvents: [],
      routedUcid: null
    }
  },
  created() {
    if (this.$route.params.ucid != "self") this.routedUcid = this.$route.params.ucid
  },
  mounted() {
    this.loadData()
    if (this.routedUcid == "self" || this.routedUcid == null) this.loadData(this.ucid)
    else this.loadData(this.routedUcid)
  },
  updated() {
    // Workaround to load current logged pilot data when click on tab operations
    this.routedUcid = this.ucid
    this.loadData()
  },
  methods: {
    loadData() {
      this.loading = true
      const requests = [
        this.getLastEvent(),
        this.getLastFiveEvents(),
        this.getAssistance(),
        this.getSurvival(),
        this.getSurvivalEvents(),
        this.getKills(),
        this.getDeaths(),
        this.getKillList(),
        this.getFlightHours(),
        this.getFlightHoursLastFiveEvents(),
        ]

      Promise.all(requests).then(() => {
      }).catch(error => {
        this.error = error
        console.log(error)
      }).finally(() => {
        this.loading = false
      })
    },
    async getLastEvent() {
      const response = await events.getLastEventByUCID(this, this.routedUcid || this.ucid)
      if (response.values && response.values[0]) {
        this.lastEvent.name = response.values[0].name || null
        this.lastEvent.event_date = format(parseISO(response.values[0].event_date), "dd-MM-Y") || null
      }
      return response
    },
    async getLastFiveEvents() {
      const response = await events.getLastFiveEvenstByUCID(this, this.routedUcid || this.ucid)
      if (response.values) {
        this.lastFiveEvents = response.values
        return response.values
      }
    },
    async getAssistance() {
      const response = await events.getAssistanceByUCID(this, this.routedUcid || this.ucid)
      if (response.values || response.values[0]) {
        this.assistance = response.values[0].events
      }
    },
    async getSurvival() {
      const response = await events.getSurvivalByUCID(this, this.routedUcid || this.ucid) //TODO
      if (response) this.survival = response.values[0].events
    },
    async getSurvivalEvents() {
      const response = await events.getSurvivalEventsByUCID(this, this.routedUcid || this.ucid)
      if (response) this.survivalEvents = response.values
    },
    async getKills() {
      const response = await events.getKillsByUCID(this, this.routedUcid || this.ucid) // TODO
      if (response) this.kills = response
    },
    async getDeaths() {
      const response = await events.getDeathsByUCID(this, this.routedUcid || this.ucid) // TODO
      if (response) this.deaths = response
    },
    async getKillList() {
      const response = await events.getKillListByUCID(this, this.routedUcid || this.ucid) // TODO
      if (response) this.killList = response.values
    },
    async getFlightHours() {
      const response = await events.getFlightHoursByUCID(this, this.routedUcid || this.ucid)
      if (response) this.flightHours = response
    },
    async getFlightHoursLastFiveEvents() {
      const response = await events.getFlightHoursLastFiveEventByUCID(this, this.routedUcid || this.ucid)
      if (response) {
        this.flightHoursLastEvent = (response.values[0].time_inair / 3600).toFixed(2)
        this.flightHoursLastFiveEvents = response.values
      }
    },    
    formatDate(dateTarget, formatRequired) {
      return format(parseISO(dateTarget), formatRequired)
    }
  }
}
</script>

<style scoped lang="scss">
.my-card {
  border: 2px solid $yellow-9;
  width: 300px;
}
.div-container {
  border: 2px solid $yellow-9;
}
.trend {
  height: 150px;
}

.section-middle {
  height: 120px;
}
.section-top {
  height: 120px;
}
.section-bottom {
  height: 350px;
}
</style>
